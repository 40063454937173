import * as React from "react"
import { getAuth, signOut } from "@firebase/auth"
import {getFirestore, collection} from "@firebase/firestore"
import {Space, Row, Col, Typography} from "antd"
import HeroContainer from "../components/HeroContainer"
import "antd/dist/antd.css"
import { getApp } from "@firebase/app"

const {Label, Link, Text} = Typography;

const UnAuthorizedPage = ({}) => {

    const [db, setDatabase] = React.useState()
 //   const {data,status, error} = useFirestoreQuery(query)
    React.useEffect(() => {
        const db = getFirestore(getApp())
        setDatabase(collection(db, "ligas/xRJmaV0IFCKhkXN0Pi0Q/admins"))
    },[])

    const signOutAndReturn = () => {
        signOut(getAuth()).then(()=>{
                collection(db, "ligas/xRJmaV0IFCKhkXN0Pi0Q/admins")
        })
    }
    return (<> <div>
        <HeroContainer>
    <div>      
        <Space direction="vertical">
          <Row justify="center">
            <Col>
              <div className="logo" className="w-64 px-5 py-2 mb-16">
                
              </div>          
            </Col>          
          </Row>
          <Row>   
          </Row>            
          </Space>        
      </div>
      </HeroContainer>        
    </div>  
      </>)
}

export default UnAuthorizedPage